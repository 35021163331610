import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Grid, Inner } from '../components/responsive/'
import H1 from '../components/h1'
import H2 from '../components/h2'
import Banner from '../components/banner'
import Content from '../components/content'
import ContentHTML from '../components/contentHTML'
import Image from '../components/image'
import SpeakerImg from '../components/speakerImg'
import Sponser from '../components/sponser'
import MediaSponser from '../components/mediaSponser'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import Entry from '../components/entry'
import Metadata from '../components/metadata'
import './index.css'
import ImgMain from "../image/main.gif"
import ImgKatoken from "../image/top/katoken.png"
import ImgBanner from "../image/top/banner.png"
import ImgBannerSp from "../image/top/banner_sp.png"

export default ({ data }) => (
  <div>
    <Metadata />
    <Header active='home' />
<main className="contents">
  <section className="mainvisual"> <img src={ ImgMain } /> </section>
  <section className="eventcontent">
    <div className="boost"> <img src={require("../image/top/boost.svg")} /> </div>
    <div className="text">近年、世界中でデジタルシフトが加速し、事業を推進上、必要不可欠な状況となってきております。<br className="pc"/>
      多くの企業がビジネスモデルの変革に向けてDX(デジタルエクスペリエンス)に取り組む中、<br className="pc"/>
      Drupalは、DX(デジタルエクスペリエンス)・CX(顧客体験)のプラットフォームとして、<br className="pc"/>
      世界的な大企業、政府機関、大学、NGOなど大規模なサイトを中心に幅広く導入されています。</div>
    <div className="text big">顧客体験価値（CX）の最大化をテーマに、<br className="pc"/>
      Drupal最新情報や具体的な活用事例の情報共有の場として、<br className="pc"/>
      Drupal Camp DEN OSAKA ONLINE（今回は大阪を軸としたオンライン）を<br className="pc"/>
      開催いたします。</div>
    <div className="event">
      <div className="eventlogo"> <img src={require("../image/top/eventoutline.svg")} /> </div>
      <ul>
        <li>
          <p className="list-title">開催場所</p>
          <p className="list-content">オンライン開催<span>※申込者に配信先URLをご連絡します。</span></p>
        </li>
        <li>
          <p className="list-title">参加費用</p>
          <p className="list-content">無料</p>
        </li>
        <li>
          <p className="list-title">参加人数</p>
          <p className="list-content">100名<span>※先着100名は、Drupalの特製クリアファイル付き</span></p>
        </li>
        <li>
          <p className="list-title">主催</p>
          <p className="list-content">DEN(drupal Experience Network)</p>
        </li>
        <li>
          <p className="list-title">実行委員長</p>
          <p className="list-content">西本&nbsp;邦男</p>
        </li>
      </ul>
    </div>
  </section>
  <section className="topic">
    <div className="intopic abs">
      <h3>イベントの目的</h3>
      <div className="blocklist">
        <div className="miniblock"> <img src={require("../image/top/mb-1.svg")} />
          <p>Drupal関連の<br className="pc"/>最先端の動向を<br className="pc"/>学びあう</p>
        </div>
        <div className="miniblock"> <img src={require("../image/top/mb-2.svg")} />
          <p>Drupalに<br className="pc"/>興味を持ってもらい<br className="pc"/>その魅力を伝える</p>
        </div>
        <div className="miniblock"> <img src={require("../image/top/mb-3.svg")} />
          <p>Drupal関連技術者の<br className="pc"/>交流の場～<br className="pc"/>年１回は集まろう！</p>
        </div>
        <div className="miniblock"> <img src={require("../image/top/mb-4.svg")} />
          <p>Drupalの<br className="pc"/>活用事例・<br className="pc"/>情報提供の場</p>
        </div>
      </div>
    </div>
    <div className="intopic">
      <h3>対象者</h3>
      <div className="people">
        <p>所属：一般ユーザー、企業、Webエージェンシー、フリーランス</p>
        <p>職種：マーケター、デザイナー、ディレクター、エンジニア、その他</p>
        <p>レベル：Drupal初心者から上級者</p>
      </div>
    </div>
  </section>
  <section className="specialsession">
    <div className="session">
      <div className="sessiontitle"><img src={require("../image/top/specialsession.svg")} /><span>スペシャルセッション</span></div>
    </div>
    <div className="namebox">
      <div className="pic"> <img src={ ImgKatoken } /> </div>
      <div className="text">
        <p className="red">官×民 複業で切り拓くトライセクターの新しい働き方</p>
        <p className="name"><span>文部科学省　大臣官房 広報戦略アドバイザー</span><br/>
          加藤&nbsp;健&nbsp;<span className="big">氏</span></p>
        <p className="des">2021年東京都港区役所・区長室の広報専門職へ。<br className="pc"/>
          文部科学省の大臣官房で広報戦略アドバイザー（非常勤国家公務員）も務め、国×地方の公務員複業を実践。<br className="pc"/>
          一般社団法人 日本GR協会 事務局長。一般社団法人 Work Design Lab 共同創業者・理事。准認定ファンドレイザー。</p>
      <div className="listener">
        <p className="role">聞き手</p>
        <p className="name">ANNAI 株式会社  代表取締役副社長<br/>
          太田垣 恭子 氏</p>
      </div>
      </div>
    </div>
    <div className="sessionlink"> <a href="/sessions">その他のセッションはこちら</a> </div>
  </section>
  <section className="onlinesession">
    <div className="orderblock">
      <p className="ban-img"><a href="https://drupal-meetup-den.connpass.com/event/229256/" target="_blank"><img src={ ImgBanner } className="pc" /><img src={ ImgBannerSp } className="sp" /></a></p>
    </div>
    <div className="about">
      <div className="block">
        <div className="about_title">Drupalグループ<br/>
          DEN(Drupal Experience Network) とは</div>
        <p className="about_des">Drupalを日本でもっと広めたいとの思いで、<br className="pc"/>
          2017年12月Drupalグループ DENを発足。</p>
        <div className="about_subtitle">地域ミートアップ</div>
        <p className="about_des">Drupal Meetup TOKYO（毎月開催)<br/>
          Drupal Meetup 羽田（毎月開催)<br/>
          Drupal Meetup 梅田（隔月開催)<br/>
          Drupal Meetup 豊田（隔月開催)</p>
        <p className="about_caution">※Connpassにて告知いたしますので、是非ご参加ください。</p>
        <a className="about_link" href="/about">詳しくはこちら</a> </div>
      <div className="block">
        <div className="about_title">Drupalとは</div>
        <p className="about_des">オープンソースのCMS・デジタルマーケティング・プラットフォーム多機能・多言語に特徴他システムとの連携グローバル企業や機関で実績Drupal Associationが運営しています。<br/>
          Drupal （ドルーパル）は、デジタルデータプラットフォームとして世界中で支持されており、テスラモーターズ、GE、NASA、といったグローバルな大企業・政府機関などで、多くの採用実績があります。<br/>
          Drupal は、単なるウェブサイトの構築・管理にとどまらず、バックエンドのプラットフォームとして利用する事が可能で、デジタルサイネージやスマートウォッチなどのデバイスに表示・通知したり、IoTやAI（人工知能）といったシステムと連携することもできます。</p>
      </div>
    </div>
  </section>
  <section className="sponser">
    <div className="sponsertitle"><img src={require("../image/top/sponser.svg")} /><span>スポンサー</span></div>
    <div className="sponserlinks">
      <ul>
        <li><a href="https://www.ibm.com/jp-ja" target="_blank"><img src={require("../image/top/ibm.svg")} /></a></li>
        <li><a href="https://issei.cc/" target="_blank"><img src={require("../image/top/issei.svg")} /></a></li>
        <li><a href="https://www.acquia.com/jp" target="_blank"><img src={require("../image/top/acquia.svg")} /></a></li>
        <li><a href="https://www.facebook.com/kazuhiro.kodama1" target="_blank"><img src={require("../image/top/waa.svg")} /></a></li>
        <li><a href="https://www.ar2.jp/" target="_blank"><img src={require("../image/top/arts.svg")} /></a></li>
        <li><a href="https://www.studio-umi.jp" target="_blank"><img src={require("../image/top/umi.svg")} /></a></li>
        <li><a href="https://optage.co.jp/" target="_blank"><img src={require("../image/top/optage.svg")} /></a></li>
        <li><a href="https://www.meta-info.co.jp/" target="_blank"><img src={require("../image/top/metainfo.svg")} /></a></li>
        <li><a href="https://www.ooi.co.jp/" target="_blank"><img src={require("../image/top/oidenki.svg")} /></a></li>
        <li><a href="https://alleyoop.jp/" target="_blank"><img src={require("../image/top/alleyoop.svg")} /></a></li>
        <li><a href="https://www.exa-corp.co.jp" target="_blank"><img src={require("../image/top/exa.svg")} /></a></li>
        <li><a href="https://synergyquest.jp/" target="_blank"><img src={require("../image/top/quest.svg")} /></a></li>
        <li><a href="https://ciandt.com/jp/ja" target="_blank"><img src={require("../image/top/cist.svg")} /></a></li>
        <li><a href="https://www.dentsudigital.co.jp/" target="_blank"><img src={require("../image/top/digital.svg")} /></a></li>
        <li><a href="https://annai.co.jp/" target="_blank"><img src={require("../image/top/annai.svg")} /></a></li>
        <li><a href="https://genero.jp" target="_blank"><img src={require("../image/top/generoLogo.png")} /></a></li>
      </ul>
    </div>
  </section>
  </main>
      
    <Footer />
  </div>
)

export const query = graphql`
  query {
    camp2021{
      rest{
        contents{
          field_keynote_speaker_title,
          field_keynote_speaker_name,
          field_keynote_speaker_desc,
          field_schedule,
          field_video_description
        }
        sponsers {
          field_sponser_url
          field_sponser_logo
          field_sponser_number
          fileName
        }
      }
    }
    main:file(relativePath: { eq: "main.gif" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`


const PCbr = styled.br`
`
